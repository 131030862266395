import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    align-self: stretch;
    box-sizing: border-box;
    width: 100%;
    background: ${({ theme }) => theme.background.default};
    padding: ${({ theme }) => `${theme.spacing(8)} ${theme.spacing(4)}`};
    gap: ${({ theme }) => theme.spacing(8)};
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    max-width: 768px;
    gap: ${({ theme }) => theme.spacing(6)};
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    gap: ${({ theme }) => theme.relatedItemsMargin}px;
`;

export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.border.alternative};
`;
