import i18n from '../i18n';
import { ProgressiveTariff, Tariff, TariffType, TimezonedTariff } from '../models/tariff';
import { formatCost } from './formatters';

export function getTimeBasedPrice(
    pricePerHour: number,
    hourDivisor: number,
    currency: string,
    length = 'long'
) {
    if (pricePerHour === 0) {
        return null;
    }

    switch (hourDivisor) {
        case 1:
            return i18n.t(`priceInformation.timeBased.perHour.${length}`, [
                formatCost(pricePerHour, currency),
            ]);
        case 60:
            return i18n.t(`priceInformation.timeBased.perMinute.${length}`, [
                formatCost(pricePerHour / 60, currency),
            ]);

        default:
            return i18n.t(`priceInformation.timeBased.perStarted.${length}`, [
                formatCost(pricePerHour / hourDivisor, currency),
                60 / hourDivisor,
            ]);
    }
}

function getProgressiveLimit(nrOfMinutes: number, length = 'long') {
    const hours = Math.floor(nrOfMinutes / 60);
    if (nrOfMinutes < 60) {
        return i18n.t(`priceInformation.limit.progressive.minutes.${length}`, [nrOfMinutes]);
    }
    if (nrOfMinutes === 60) {
        return i18n.t(`priceInformation.limit.progressive.hour.${length}`);
    }
    return i18n.t(`priceInformation.limit.progressive.hoursAndMinutes.${length}`, [
        hours,
        nrOfMinutes % 60,
    ]);
}

function getProgressiveTimeLimit(nrOfMinutes: number, length = 'long') {
    return i18n.t(`priceInformation.limit.progressive.divider.${length}`, [
        getProgressiveLimit(nrOfMinutes, length),
    ]);
}

function getTimeZoneLimit(zoneHrStart: number, zoneHrEnd: number, length = 'long') {
    return i18n.t(`priceInformation.limit.timezone.divider.${length}`, [zoneHrStart, zoneHrEnd]);
}

function getKwBasedPrice(pricePerKwh: number, currency: string, length = 'long') {
    if (pricePerKwh === 0) {
        return null;
    }

    return i18n.t(`priceInformation.kWBased.${length}`, [formatCost(pricePerKwh, currency)]);
}

function getFixedPrice(fixedCost: number, currency: string, length = 'long') {
    if (fixedCost > 0) {
        return i18n.t(`priceInformation.fixed.${length}`, [formatCost(fixedCost, currency)]);
    }
    return null;
}

export function getLinearPrice(
    pricePerHour: number,
    hourDivisor: number,
    pricePerKwh: number,
    fixedCost: number,
    currency: string,
    length = 'long'
): string {
    let priceStrings = [];
    priceStrings = [
        getTimeBasedPrice(pricePerHour, hourDivisor, currency, length),
        getKwBasedPrice(pricePerKwh, currency, length),
        getFixedPrice(fixedCost, currency, length),
    ].filter((exists) => exists);
    if (priceStrings.length < 1) {
        return i18n.t(`priceInformation.free.${length}`);
    }

    return priceStrings.join(' + ');
}

export function getProgressivePrice(tariff: ProgressiveTariff, currency: string, length = 'long') {
    const priceStrings = [
        getLinearPrice(
            tariff.pricePerHour,
            tariff.hourDivisor,
            tariff.pricePerKwh,
            tariff.fixedCost,
            currency,
            length
        ),
        getProgressiveTimeLimit(tariff.nrOfMinutes, length),
        getLinearPrice(
            tariff.pricePerHour2,
            tariff.hourDivisor,
            tariff.pricePerKwh,
            0,
            currency,
            length
        ),
    ].filter((exists) => exists);
    return priceStrings.join(' ');
}

export function getTimeZonedPrice(tariff: TimezonedTariff, currency: string, length = 'long') {
    const priceStrings = [
        getLinearPrice(
            tariff.pricePerHourZone,
            tariff.hourDivisor,
            tariff.pricePerKwhZone,
            tariff.fixedCost,
            currency,
            length
        ),
        getTimeZoneLimit(tariff.zoneHrStart, tariff.zoneHrEnd, length),
        getLinearPrice(
            tariff.pricePerHour,
            tariff.hourDivisor,
            tariff.pricePerKwh,
            tariff.fixedCost,
            currency,
            length
        ),
    ].filter((exists) => exists);
    return priceStrings.join(' ');
}
export function getPriceInfo(tariff: Tariff | null, currencyCode: string, length = 'long') {
    if (!tariff) {
        return null;
    }
    switch (tariff.type) {
        case TariffType.LinearTariff:
            return getLinearPrice(
                tariff.pricePerHour,
                tariff.hourDivisor,
                tariff.pricePerKwh,
                tariff.fixedCost,
                currencyCode,
                length
            );
        case TariffType.ProgressiveTariff:
            return getProgressivePrice(tariff, currencyCode, length);
        case TariffType.TimezonedTariff:
            return getTimeZonedPrice(tariff, currencyCode, length);
        default:
            return null;
    }
}

export function isFreeTariff(tariff?: Tariff | null) {
    if (!tariff) {
        return true;
    }

    const { type, fixedCost, pricePerHour, pricePerKwh } = tariff;

    switch (type) {
        case TariffType.LinearTariff:
            return !pricePerHour && !pricePerKwh && !fixedCost;
        case TariffType.ProgressiveTariff:
            return !pricePerHour && !pricePerKwh && !fixedCost && !tariff.pricePerHour2;
        case TariffType.TimezonedTariff:
            return (
                !pricePerHour &&
                !pricePerKwh &&
                !fixedCost &&
                !tariff.pricePerHourZone &&
                !tariff.pricePerKwhZone
            );
        default:
            return true;
    }
}
