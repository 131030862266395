import { ThemeProvider, themesMap } from '@defa/defa-component-library';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    RouterProvider,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

import App from './app';
import { CookieConsentProvider } from './contexts/cookie-consent';
import ErrorPage from './error-page';
import { AppLayout } from './fractions/app-layout';
import { updateLocale } from './i18n';
import { Charger } from './screens/charger';
import { LandingPage } from './screens/landing-page';
import { Session } from './screens/session';
import { Summary } from './screens/summary';
import api from './utils/api';

const brand = import.meta.env.VITE_BRAND_KEY;

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        'localhost',
        /^https:\/\/go\.cloudcharge\.tech/,
        /^https:\/\/(demo|dev|staging)\.go\.cloudcharge\.tech/,
    ],

    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
    {
        path: '/',
        element: <App />,
        errorElement: (
            <AppLayout>
                <ErrorPage />
            </AppLayout>
        ),
        children: [
            { index: true, element: <LandingPage /> },
            { path: '/:chargerId', element: <Charger />, loader: api.chargerLoader },
            {
                path: '/:chargerId/session/:userId',
                element: <Session />,
                loader: api.sessionLoader,
            },
            {
                path: '/:chargerId/session/:userId/summary',
                element: <Summary />,
                loader: api.summaryLoader,
            },
        ],
    },
]);

// Setting Application language based on Browser language
updateLocale(navigator.language);

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ThemeProvider theme={themesMap[brand]}>
            <CookieConsentProvider>
                <RouterProvider router={router} />
            </CookieConsentProvider>
        </ThemeProvider>
    </React.StrictMode>
);
