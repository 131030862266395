import { styled } from '@defa/defa-component-library';

export const Root = styled.div`
    display: flex;
    justify-content: center;
`;

export const Image = styled.img`
    width: 150px;
`;
