import { styled } from '@defa/defa-component-library';

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

export const PlugInVehicleSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(6)};
`;

export const ClickableArea = styled.a`
    cursor: pointer;
`;
