import { Heading, Level } from '@defa/defa-component-library';
import * as Sentry from '@sentry/browser';
import { useEffect, useState } from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';

import { Content, Section } from '../../fractions/app-layout';
import { PaymentSection } from '../../fractions/payment-section/payment-section';
import { ReceiptCard } from '../../fractions/receipt-card';
import { ChargerStatusCard, SessionStatusCard } from '../../fractions/status-card';
import i18n from '../../i18n';
import { ConnectorStatus } from '../../models/connector';
import { ErrorData } from '../../models/error';
import { SessionData, SessionLoaderData } from '../../models/session';
import api from '../../utils/api';
import { callStartPingWithErrorCapture } from '../../utils/api.helpers';
import { useInterval } from '../../utils/hooks';
import { anonymousConnectorStatus } from '../../utils/status';
import { unavailableCharger } from '../charger/charger.helpers';
import { getReservationCost, getSimplifiedStatus, userNotFoundError } from './session.helpers';

export function Session() {
    const { chargerId, userId } = useParams();
    const navigate = useNavigate();
    const {
        charger: inititalCharger,
        session: initialSession,
        user,
    } = useLoaderData() as SessionLoaderData;
    const userError = !user || 'message' in user;

    const [charger, setCharger] = useState(inititalCharger);
    const chargerError = !charger || 'message' in charger;

    const [session, setSession] = useState(initialSession);
    const ongoingSession = session && 'status' in session;
    const sessionErrorMessage = !ongoingSession ? session.message : null;

    const [showStartButton, setShowStartButton] = useState(false);
    const [startButtonError, setStartButtonError] = useState(false);
    const [startButtonLoading, setStartButtonLoading] = useState(false);

    const simplifiedStatus = getSimplifiedStatus(session, charger);

    const paused =
        simplifiedStatus === ConnectorStatus.FINISHING ||
        simplifiedStatus === ConnectorStatus.SUSPENDED_EV;

    const userNotFound = userNotFoundError(user) || userNotFoundError(session);

    const onClickStart = () => {
        if (chargerId && userId) {
            setStartButtonLoading(true);
            setStartButtonError(false);

            api.startCharging(chargerId, userId)
                .then((response) => {
                    if (response.ok) {
                        setTimeout(() => setStartButtonLoading(false), 30000);
                    } else {
                        setStartButtonLoading(false);
                        setStartButtonError(true);
                    }
                })
                .catch((error) => {
                    setStartButtonLoading(false);
                    setStartButtonError(true);

                    Sentry.captureException(error);
                    console.error(error);
                });
        }
    };

    useInterval(() => {
        api.getChargeSession(userId, chargerId)
            .then(async (response) => {
                const responseData = (await response.json()) as SessionData | ErrorData;
                setSession(responseData);

                // Only fetch charger data until we have an ongoing session
                if (
                    'message' in responseData &&
                    responseData.message === 'NO_ONGOING_CHARGE_SESSION'
                ) {
                    const chargerResponse = await api.getCharger(chargerId);

                    if (chargerResponse.ok && chargerResponse.data) {
                        setCharger(chargerResponse.data);
                    }
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.error(error);
            });
    }, 5000);

    useEffect(() => {
        setTimeout(() => {
            setShowStartButton(true);
        }, 30000);
    }, []);

    useInterval(() => {
        callStartPingWithErrorCapture(chargerId);
    }, 150000);

    useEffect(() => {
        callStartPingWithErrorCapture(chargerId);
    }, [chargerId]);

    useEffect(() => {
        if (!ongoingSession) {
            api.getChargeSessionSummary(userId)
                .then((response) => {
                    if (response.ok) {
                        navigate(`/${chargerId}/session/${userId}/summary`);
                    }
                })
                .catch((error) => {
                    Sentry.captureException(error);
                    console.error(error);
                });
        }
    }, [chargerId, navigate, ongoingSession, simplifiedStatus, userId]);

    useEffect(() => {
        if (userNotFound || sessionErrorMessage === 'NOT_OWNER_OF_CHARGE_SESSION') {
            navigate(`/${chargerId}`);
        }
    }, [chargerId, navigate, sessionErrorMessage, userNotFound]);

    return (
        <Content>
            {ongoingSession ? (
                <SessionStatusCard session={session} status={simplifiedStatus} />
            ) : (
                <ChargerStatusCard
                    charger={chargerError ? unavailableCharger(chargerId) : charger}
                    onClickStart={onClickStart}
                    promptPlugIn
                    showStart={showStartButton}
                    startButtonError={startButtonError}
                    startButtonLoading={startButtonLoading}
                    status={
                        chargerError
                            ? ConnectorStatus.UNAVAILABLE
                            : anonymousConnectorStatus(simplifiedStatus, charger.offline)
                    }
                />
            )}

            {!paused && !userError && (
                <>
                    <Section>
                        <Heading level={Level.h3}>{i18n.t('receipt.title')}</Heading>
                        <ReceiptCard />
                    </Section>
                    <PaymentSection
                        paymentCard={user.paymentCard.maskedPan}
                        reservationCost={getReservationCost(session, charger)}
                        title={i18n.t('payment.method.title')}
                    />
                </>
            )}
        </Content>
    );
}
