import { ChargerData, ConnectorStatus } from '../../models/connector';
import { ErrorData } from '../../models/error';
import { SessionData } from '../../models/session';
import { UserData } from '../../models/user';
import { formatCost } from '../../utils/formatters';
import { sessionConnectorStatus } from '../../utils/status';

export function getSimplifiedStatus(
    session: SessionData | ErrorData,
    charger: ChargerData | ErrorData
) {
    const sessionError = !session || 'message' in session;
    const chargerError = !charger || 'message' in charger;

    if (sessionError && chargerError) {
        return sessionConnectorStatus(ConnectorStatus.UNAVAILABLE, false);
    }

    // Typescript doesn't understand that both can't have error at this stage
    const { offline, status } = sessionError ? (charger as ChargerData) : session;

    return sessionConnectorStatus(status, offline);
}

export function getReservationCost(
    session: SessionData | ErrorData,
    charger: ChargerData | ErrorData
) {
    const sessionError = !session || 'message' in session;
    const chargerError = !charger || 'message' in charger;

    if (sessionError && chargerError) {
        return undefined;
    }

    // Typescript doesn't understand that both can't have error at this stage
    const { currency, reservationAmount } = sessionError ? (charger as ChargerData) : session;

    return formatCost(reservationAmount, currency);
}

export function userNotFoundError(obj: ChargerData | SessionData | UserData | ErrorData) {
    return 'message' in obj && obj.message === 'NOT_FOUND';
}
