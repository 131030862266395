import { I18n } from 'i18n-js';

import en from './translations/en.json';
import fi from './translations/fi.json';
import nb from './translations/nb.json';
import sv from './translations/sv.json';

const i18n = new I18n({ en, fi, nb, nn: nb, no: nb, sv });
i18n.defaultLocale = 'en';
i18n.enableFallback = true;

export const updateLocale = (language: string) => {
    const languageCode = language.split('-')[0];
    if (!i18n.translations[languageCode]) {
        return;
    }
    i18n.locale = languageCode;
};

export default i18n;
