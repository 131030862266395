import { createContext, useCallback, useEffect } from 'react';

import { useLocalStorage } from '../utils/hooks';

interface CookieConsentCookie {
    essential: boolean;
    nonEssential: boolean;
    acceptedAt?: number;
}

interface CookieConsentContextState {
    acceptedEssential: boolean;
    acceptedNonEssential: boolean;
    acceptAll: () => void;
    acceptEssentialOnly: () => void;
}

const noConsent: CookieConsentCookie = {
    essential: false,
    nonEssential: false,
};

export const CookieConsentContext = createContext<CookieConsentContextState>({
    acceptedEssential: false,
    acceptedNonEssential: false,
    acceptAll: () => {
        throw new Error('acceptAll method not implemented');
    },
    acceptEssentialOnly: () => {
        throw new Error('acceptEssentialOnly method not implemented');
    },
});

export function CookieConsentProvider({ children }: { children: React.ReactNode }) {
    const [cookieConsent, setCookieConsent] = useLocalStorage<CookieConsentCookie>(
        'cookieConsent',
        noConsent
    );

    const acceptAll = useCallback(() => {
        setCookieConsent({ essential: true, nonEssential: true, acceptedAt: Date.now() });
    }, [setCookieConsent]);

    const acceptEssentialOnly = useCallback(() => {
        setCookieConsent({ essential: true, nonEssential: false, acceptedAt: Date.now() });
    }, [setCookieConsent]);

    useEffect(() => {
        if (cookieConsent?.acceptedAt) {
            const oneYear = 1000 * 60 * 60 * 24 * 365;
            const duration = Date.now() - cookieConsent.acceptedAt;
            // Reset cookie consent if it was given more than a year ago
            if (duration > oneYear) {
                setCookieConsent(noConsent);
            }
        } else if (!cookieConsent?.acceptedAt && cookieConsent?.essential) {
            // Reset cookie consent if acceptedAt is missing while essential is accepted
            setCookieConsent(noConsent);
        }
    }, [cookieConsent, setCookieConsent]);

    const contextState = {
        acceptedEssential: cookieConsent?.essential ?? false,
        acceptedNonEssential: cookieConsent?.nonEssential ?? false,
        acceptAll,
        acceptEssentialOnly,
    };

    return (
        <CookieConsentContext.Provider value={contextState}>
            {children}
        </CookieConsentContext.Provider>
    );
}
