import { Link } from '@defa/defa-component-library';

import i18n from '../../i18n';
import { Divider, StyledFooter } from './footer.styles';

const brand = import.meta.env.VITE_BRAND_KEY;

export function Footer() {
    return (
        <StyledFooter>
            <Link href={i18n.t(`url.privacyPolicy.${brand}`)} newTab>
                {i18n.t('footer.privacyPolicy')}
            </Link>
            <Divider>|</Divider>
            <Link href={i18n.t(`url.terms.${brand}`)} newTab>
                {i18n.t('footer.terms')}
            </Link>
        </StyledFooter>
    );
}
