import { styled } from '@defa/defa-component-library';

export const StyledFooter = styled.footer`
    margin-top: auto;
`;

export const Divider = styled.span`
    font-size: ${({ theme }) => theme.descriptionSize};
    color: ${({ theme }) => theme.foreground.subtle};
    margin: 0 ${({ theme }) => theme.spacing(3)};
`;
