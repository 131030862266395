import {
    Card,
    Heading,
    Level,
    Link,
    Size,
    Text,
    TextType,
    useTheme,
} from '@defa/defa-component-library';

import { AppStore, GooglePlay } from '../../assets/images';
import i18n from '../../i18n';
import { CardContent } from '../card';
import { Badges, Image, Texts } from './app-promo.styles';

const brand = import.meta.env.VITE_BRAND_KEY;

export function AppPromo() {
    const theme = useTheme();

    const appStoreLink = i18n.t(`url.appStore.${brand}`, {
        defaultValue: i18n.t<string>('url.appStore.cloudcharge'),
    });

    const googlePlayLink = i18n.t(`url.googlePlay.${brand}`, {
        defaultValue: i18n.t<string>('url.googlePlay.cloudcharge'),
    });

    return (
        <Card clickable={false} size={Size.LARGE}>
            <CardContent gap={theme.spacingRaw(6)}>
                <Texts>
                    <Heading level={Level.h3} color={theme.foreground.alternative}>
                        {i18n.t('promotionCard.title')}
                    </Heading>
                    <Text type={TextType.body} color={theme.foreground.alternative}>
                        {i18n.t('promotionCard.description')}
                    </Text>
                </Texts>
                <Badges>
                    <Link href={appStoreLink} newTab>
                        <Image src={AppStore} alt={i18n.t('imageDescription.appStore')} />
                    </Link>
                    <Link href={googlePlayLink} newTab>
                        <Image src={GooglePlay} alt={i18n.t('imageDescription.googlePlay')} />
                    </Link>
                </Badges>
            </CardContent>
        </Card>
    );
}
