import { ReactNode } from 'react';

import { StyledCardContent } from './card.styles';

interface CardContentProps {
    children: ReactNode;
    gap?: number;
}

export function CardContent({ children, gap }: CardContentProps) {
    return <StyledCardContent gap={gap}>{children}</StyledCardContent>;
}
