import { Card, Heading, Level, Size, Text, TextType } from '@defa/defa-component-library';

import { Content } from '../../fractions/app-layout';
import i18n from '../../i18n';

export function LandingPage() {
    return (
        <Content>
            <Heading level={Level.h2} textAlign="center">
                {i18n.t('welcome.title')}
            </Heading>
            <Card clickable={false} size={Size.LARGE}>
                <Text type={TextType.body}>{i18n.t('welcome.description')}</Text>
            </Card>
        </Content>
    );
}
