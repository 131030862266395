import {
    Button,
    Composite,
    Heading,
    Level,
    Size,
    Text,
    TextInput,
    TextInputMode,
    TextType,
    useTheme,
    Variant,
} from '@defa/defa-component-library';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import i18n from '../../i18n';
import api from '../../utils/api';
import { validateEmail } from '../../utils/email';
import { CardContent } from '../card';

interface ReceiptContentProps {
    buttonText?: string;
    buttonLoadingText?: string;
    emailAddedDescription?: string;
    emailMissingDescription?: string;
    initialEmail?: string;
    sendReceiptDirectly?: boolean;
}

export function ReceiptContent({
    buttonText = i18n.t('statusCard.button.getReceipt'),
    buttonLoadingText = '',
    emailAddedDescription = i18n.t('receipt.addedReceipt.description'),
    emailMissingDescription = i18n.t('receipt.description'),
    initialEmail,
    sendReceiptDirectly = false,
}: ReceiptContentProps) {
    const { userId } = useParams();
    const theme = useTheme();

    const [email, setEmail] = useState('');
    const [savedEmail, setSavedEmail] = useState(initialEmail);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [changeMode, setChangeMode] = useState(false);
    const [loading, setLoading] = useState(false);

    const validEmail = validateEmail(email);

    const onChangeClick = useCallback(() => {
        setChangeMode(true);
        setEmail(savedEmail ?? '');
    }, [savedEmail]);

    const onGetReceiptClick = useCallback(() => {
        setLoading(true);
        if (userId && validEmail) {
            const apiFunction = sendReceiptDirectly ? api.sendReceipt : api.addReceiptEmail;
            apiFunction(userId, email)
                .then((response) => {
                    if (response.ok) {
                        setChangeMode(false);
                        setErrorMessage(null);
                        setSavedEmail(email);
                    } else {
                        setErrorMessage(i18n.t('error.generic.title'));
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setErrorMessage(i18n.t('error.generic.title'));
                    setLoading(false);

                    Sentry.captureException(error);
                    console.error(error);
                });
        }
    }, [email, sendReceiptDirectly, userId, validEmail]);

    useEffect(() => {
        api.getUserData(userId)
            .then((userResponse) => {
                if (userResponse.ok && userResponse.data) {
                    setSavedEmail(userResponse.data.email);
                }
            })
            .catch((error) => {
                setErrorMessage(i18n.t('error.generic.title'));

                Sentry.captureException(error);
                console.error(error);
            });
    }, [userId]);

    return savedEmail && !changeMode ? (
        <CardContent>
            <Heading level={Level.h4Medium} color={theme.foreground.alternative} ellipsis>
                {emailAddedDescription}
            </Heading>
            <Composite justify="space-between">
                <Heading level={Level.h4Medium} color={theme.foreground.default} ellipsis>
                    {savedEmail}
                </Heading>
                <Button
                    variant={Variant.TEXT}
                    size={Size.SMALL}
                    onClick={onChangeClick}
                    fillParent={false}
                >
                    {i18n.t('button.change')}
                </Button>
            </Composite>
            {errorMessage && (
                <Text type={TextType.description} color={theme.foreground.error}>
                    {errorMessage}
                </Text>
            )}
        </CardContent>
    ) : (
        <CardContent>
            {emailMissingDescription && (
                <Heading level={Level.h4Medium} color={theme.foreground.alternative}>
                    {emailMissingDescription}
                </Heading>
            )}
            <TextInput
                name="receipt-email"
                mode={TextInputMode.EMAIL}
                label={i18n.t('inputFields.email.label')}
                value={email}
                onChange={setEmail}
                flex="auto"
            />
            {errorMessage && (
                <Text type={TextType.description} color={theme.foreground.error}>
                    {errorMessage}
                </Text>
            )}
            <Button disabled={!validEmail || loading} loading={loading} onClick={onGetReceiptClick}>
                {loading ? buttonLoadingText : buttonText}
            </Button>
        </CardContent>
    );
}
