import { useTheme } from '@defa/defa-component-library';

import i18n from '../../i18n';
import { formatCost, formatDuration, formatKwh } from '../../utils/formatters';
import { CardBase, Divider } from '../card';
import { ChargerInfoItem } from '../charger-info-item';
import { ReceiptContent } from '../receipt-card';

interface SummaryCardProps {
    cost: number;
    currency: string;
    duration: number;
    energy: number;
    initialEmail: string;
    paymentProcessing: boolean;
}
export function SummaryCard({
    cost,
    currency,
    duration,
    energy,
    initialEmail,
    paymentProcessing,
}: SummaryCardProps) {
    const theme = useTheme();

    return (
        <CardBase
            heading={i18n.t('summaryCard.title')}
            icon="checkCircle"
            iconColor={theme.foreground.success}
            iconSize={40}
            secondaryText={i18n.t('summaryCard.description')}
        >
            <ChargerInfoItem
                icon="costFill"
                text={
                    paymentProcessing
                        ? i18n.t('summaryCard.paymentProcessing')
                        : formatCost(cost, currency)
                }
                textColor={theme.foreground.alternative}
            />
            <ChargerInfoItem
                icon="boltFill"
                text={formatKwh(energy)}
                textColor={theme.foreground.alternative}
            />
            <ChargerInfoItem
                icon="clockFill"
                text={formatDuration(duration)}
                textColor={theme.foreground.alternative}
            />
            <Divider />
            <ReceiptContent
                buttonText={i18n.t('summaryCard.button.sendReceipt')}
                buttonLoadingText={i18n.t('summaryCard.button.sendReceiptLoading')}
                emailAddedDescription={i18n.t('summaryCard.receipt.description')}
                emailMissingDescription={i18n.t('receipt.getReceipt.title')}
                initialEmail={initialEmail}
                sendReceiptDirectly
            />
        </CardBase>
    );
}
