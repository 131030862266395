import { Heading, Level, Loading } from '@defa/defa-component-library';
import * as Sentry from '@sentry/browser';
import { useCallback, useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';

import { Content } from '../../fractions/app-layout';
import { PaymentSection } from '../../fractions/payment-section/payment-section';
import { ChargerStatusCard } from '../../fractions/status-card';
import i18n from '../../i18n';
import { ChargerData } from '../../models/connector';
import api from '../../utils/api';
import { callStartPingWithErrorCapture } from '../../utils/api.helpers';
import { formatCost } from '../../utils/formatters';
import { useInterval } from '../../utils/hooks';
import { isFreeTariff } from '../../utils/priceInfo';
import { anonymousConnectorStatus } from '../../utils/status';
import { LoadingWrapper } from './charger.styles';

export function Charger() {
    const { chargerId } = useParams();
    const inititalCharger = useLoaderData() as ChargerData;
    const [charger, setCharger] = useState(inititalCharger);
    const simplifiedStatus = anonymousConnectorStatus(charger.status, charger?.offline);

    const [clickedStart, setClickedStart] = useState(false);
    const [clientSecret, setClientSecret] = useState<string>();

    const isFree = isFreeTariff(charger.tariff);

    const makePaymentReservation = useCallback(async () => {
        if (chargerId) {
            try {
                const result = await api.initPaymentReservation(chargerId);
                if (result.ok) {
                    setClientSecret(result.data?.stripeKey);
                }
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        }
    }, [chargerId]);

    const onClickStart = useCallback(() => {
        setClickedStart(true);
        void makePaymentReservation();
    }, [makePaymentReservation]);

    useInterval(() => {
        api.getCharger(chargerId)
            .then((response) => {
                if (response.ok && response.data) {
                    setCharger(response.data);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.error(error);
            });
    }, 10000);

    useInterval(() => {
        callStartPingWithErrorCapture(chargerId);
    }, 150000);

    useEffect(() => {
        callStartPingWithErrorCapture(chargerId);
    }, [chargerId]);

    return (
        <Content>
            <Heading level={Level.h2} textAlign="center">
                {i18n.t('welcome.title')}
            </Heading>
            {charger ? (
                <>
                    <ChargerStatusCard
                        charger={charger}
                        onClickStart={onClickStart}
                        promptPlugIn={isFree}
                        showStart={!clickedStart && !isFree}
                        status={simplifiedStatus}
                    />
                    {clickedStart && (
                        <PaymentSection
                            clientSecret={clientSecret}
                            offline={charger.offline}
                            reservationCost={formatCost(
                                charger.reservationAmount,
                                charger.currency
                            )}
                            title={i18n.t('payment.title')}
                        />
                    )}
                </>
            ) : (
                <LoadingWrapper>
                    <Loading />
                </LoadingWrapper>
            )}
        </Content>
    );
}
