import { styled } from '@defa/defa-component-library';

import { Divider as AppDivider } from '../app-layout';

export const Divider = styled(AppDivider)`
    margin-top: ${({ theme }) => theme.relatedItemsMargin}px;
`;

export const IconContainer = styled.span<{ color?: string }>`
    display: flex;
    width: ${({ theme }) => theme.spacing(10)};
    height: ${({ theme }) => theme.spacing(10)};
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.spacing(5)};
    background-color: ${({ color }) => color ?? 'transparent'};
`;

export const StyledCardContent = styled.div<{ gap?: number }>`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: ${({ gap, theme }) => (gap ? gap : theme.relatedItemsMargin)}px;
    width: 100%;
`;
