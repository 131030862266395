import { GlobalStyle, LocalStyle } from '@defa/defa-component-library';
import { ReactNode } from 'react';

import { Footer } from '../footer';
import { Header } from '../header';
import { Root } from './app-layout.styles';

export function AppLayout({ children }: { children: ReactNode }) {
    return (
        <Root>
            <GlobalStyle />
            <LocalStyle />
            <Header />
            {children}
            <Footer />
        </Root>
    );
}
