import { styled } from '@defa/defa-component-library';

export const Texts = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing(1)};
`;

export const Badges = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.spacing(6)};
`;

export const Image = styled.img`
    width: 210px;
`;
