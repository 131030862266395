import * as Sentry from '@sentry/react';
import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { CookieConsentContext } from './contexts/cookie-consent';
import { AppLayout } from './fractions/app-layout';
import { CookieModal } from './fractions/cookie-modal';
import { useSessionStorage } from './utils/hooks';

export default function App() {
    const { acceptedEssential, acceptedNonEssential } = useContext(CookieConsentContext);
    const [sentryReplayCookie, , removeSentryCookie] = useSessionStorage('sentryReplaySession');

    useEffect(() => {
        if (acceptedNonEssential && !sentryReplayCookie) {
            void import('@sentry/browser').then((lazyLoadedSentry) => {
                Sentry.addIntegration(lazyLoadedSentry.replayIntegration());
            });
        } else if (!acceptedNonEssential && acceptedEssential && sentryReplayCookie) {
            removeSentryCookie();
        }
    }, [acceptedEssential, acceptedNonEssential, removeSentryCookie, sentryReplayCookie]);

    return (
        <AppLayout>
            <Outlet />
            <CookieModal showModal={!acceptedEssential} />
        </AppLayout>
    );
}
