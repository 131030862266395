import { Card, Size } from '@defa/defa-component-library';

import { ReceiptContent } from './receipt-content';

interface ReceiptCardProps {
    initialEmail?: string;
}

export function ReceiptCard({ initialEmail }: ReceiptCardProps) {
    return (
        <Card clickable={false} size={Size.LARGE}>
            <ReceiptContent initialEmail={initialEmail} />
        </Card>
    );
}
