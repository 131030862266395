export enum TariffType {
    LinearTariff = 'LinearTariff',
    ProgressiveTariff = 'ProgressiveTariff',
    TimezonedTariff = 'TimezonedTariff',
}

export interface BaseTariff {
    type: TariffType;
    fixedCost: number;
    hourDivisor: number;
    pricePerHour: number;
    pricePerKwh: number;
}

export interface LinearTariff extends BaseTariff {
    type: TariffType.LinearTariff;
}

export interface ProgressiveTariff extends BaseTariff {
    type: TariffType.ProgressiveTariff;
    nrOfMinutes: number;
    pricePerHour2: number;
}

export interface TimezonedTariff extends BaseTariff {
    type: TariffType.TimezonedTariff;
    pricePerHourZone: number;
    pricePerKwhZone: number;
    zoneHrStart: number;
    zoneHrEnd: number;
}

export type Tariff = LinearTariff | ProgressiveTariff | TimezonedTariff;
