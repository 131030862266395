import { json, Params } from 'react-router-dom';

import i18n from '../i18n';
import { ChargerData } from '../models/connector';
import { ErrorData } from '../models/error';
import { SessionData, SummaryData } from '../models/session';
import { UserData } from '../models/user';
import { unavailableCharger } from '../screens/charger/charger.helpers';

const CC_GO_API_URL = `${
    import.meta.env.VITE_CC_GO_API_URL
        ? import.meta.env.VITE_CC_GO_API_URL
        : 'https://prod.cloudcharge.se/services/ccgo'
}`;

async function call(endpoint: string, method = 'GET', data?: Record<string, unknown>) {
    const headers = new Headers({
        'Content-Type': 'application/json',
    });

    const response: Response = await fetch(endpoint, {
        headers,
        method,
        body: JSON.stringify(data),
    });

    return response;
}

async function typedCall<T>(
    endpoint: string,
    method = 'GET',
    data?: Record<string, unknown>
): Promise<ApiResponse<T>> {
    const response = await call(endpoint, method, data);
    const { ok, status } = response;

    try {
        const repsonseData = (await response.json()) as T | ErrorData;
        return ok
            ? { data: repsonseData as T, status, ok }
            : { data: repsonseData as ErrorData, status, ok };
    } catch {
        return { status, ok };
    }
}

type ApiResponse<T> = SuccessApiResponse<T> | ErrorApiResponse;

interface SuccessApiResponse<T> extends CallResponse<T> {
    ok: true;
}

interface ErrorApiResponse extends CallResponse<ErrorData> {
    ok: false;
}

interface CallResponse<R> {
    data?: R;
    status: number;
    ok: boolean;
}

interface PaymentReservationResponse {
    stripeKey: string;
}

export interface StopResponse {
    userId: string;
}

type VerifyPaymentReservationResponse = StopResponse;

// Simple calls
async function addReceiptEmail(userId: string, email: string) {
    return await call(`${CC_GO_API_URL}/user/${userId}`, 'PATCH', { email, language: i18n.locale });
}

async function getChargeSession(userId: string | undefined, chargerId: string | undefined) {
    return await call(
        `${CC_GO_API_URL}/user/${userId}/charge-session/?chargerId=${chargerId}`,
        'GET'
    );
}

async function getChargeSessionSummary(userId: string | undefined) {
    return await call(`${CC_GO_API_URL}/user/${userId}/charge-session/summary`, 'GET');
}

async function getUser(userId: string | undefined) {
    return await call(`${CC_GO_API_URL}/user/${userId}`, 'GET');
}

async function sendReceipt(userId: string, email: string) {
    return await call(`${CC_GO_API_URL}/user/${userId}/receipt/send`, 'POST', {
        email,
        language: i18n.locale,
    });
}

async function startCharging(chargerId: string, userId: string) {
    return await call(`${CC_GO_API_URL}/charger/${chargerId}/start`, 'POST', { userId });
}

// Typed ApiResponse calls

async function getCharger(chargerId: string | undefined) {
    return await typedCall<ChargerData>(`${CC_GO_API_URL}/charger/${chargerId}`, 'GET');
}

async function getChargeSessionSummaryData(userId: string | undefined) {
    return await typedCall<SummaryData>(
        `${CC_GO_API_URL}/user/${userId}/charge-session/summary`,
        'GET'
    );
}

async function getUserData(userId: string | undefined): Promise<ApiResponse<UserData>> {
    return await typedCall<UserData>(`${CC_GO_API_URL}/user/${userId}`, 'GET');
}

async function initPaymentReservation(chargerId: string) {
    return await typedCall<PaymentReservationResponse>(
        `${CC_GO_API_URL}/charger/${chargerId}/stripe-payment-reservation`,
        'POST'
    );
}

async function startPing(chargerId: string) {
    return await typedCall(`${CC_GO_API_URL}/charger/${chargerId}/ping/start`, 'POST');
}

async function stopCharging(chargerId: string, userId: string) {
    return await typedCall<StopResponse>(`${CC_GO_API_URL}/charger/${chargerId}/stop`, 'POST', {
        userId,
    });
}

async function verifyPaymentReservation(chargerId: string, stripePaymentIntentId: string) {
    return await typedCall<VerifyPaymentReservationResponse>(
        `${CC_GO_API_URL}/charger/${chargerId}/stripe-payment-reservation/verify`,
        'POST',
        { stripePaymentIntentId }
    );
}

// Loaders
async function chargerLoader({ params }: { params: Params<'chargerId'> }) {
    const response = await call(`${CC_GO_API_URL}/charger/${params.chargerId}`, 'GET');

    if (response.ok) {
        return response;
    } else {
        return json(unavailableCharger(params.chargerId));
    }
}

async function sessionLoader({ params }: { params: Params<'chargerId' | 'userId'> }) {
    const responses: Response[] = await Promise.all([
        chargerLoader({ params }),
        getChargeSession(params.userId, params.chargerId),
        getUser(params.userId),
    ]);

    const [charger, session, user] = (await Promise.all(responses.map((r) => r.json()))) as [
        ChargerData,
        SessionData,
        UserData,
    ];

    return { charger, session, user };
}

async function summaryLoader({ params }: { params: Params<'chargerId' | 'userId'> }) {
    const responses: Response[] = await Promise.all([
        getChargeSessionSummary(params.userId),
        getUser(params.userId),
    ]);

    const [summary, user] = (await Promise.all(responses.map((r) => r.json()))) as [
        SummaryData,
        UserData,
    ];

    return { summary, user };
}

const api = {
    addReceiptEmail,
    chargerLoader,
    getCharger,
    getChargeSession,
    getChargeSessionSummary,
    getChargeSessionSummaryData,
    getUser,
    getUserData,
    initPaymentReservation,
    sendReceipt,
    sessionLoader,
    startCharging,
    startPing,
    stopCharging,
    summaryLoader,
    verifyPaymentReservation,
};

export default api;
