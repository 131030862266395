import * as Sentry from '@sentry/react';

import i18n from '../i18n';

export const formatCost = (price: number, currency: string) => {
    try {
        return Intl.NumberFormat(i18n.locale, {
            style: 'currency',
            currency,
            maximumFractionDigits: 2,
        }).format(price);
    } catch (error) {
        Sentry.captureException(error);

        const formattedNumber = Intl.NumberFormat(i18n.locale, {
            style: 'decimal',
            maximumFractionDigits: 2,
        }).format(price);
        return `${formattedNumber} ${currency}`;
    }
};

export const formatKw = (kw: number) => {
    const roundedValue = Intl.NumberFormat(i18n.locale, {
        style: 'decimal',
        maximumFractionDigits: 1,
    }).format(kw);

    return `${roundedValue} kW`;
};

export const formatKwh = (kwh: number) => {
    return `+${formatKw(kwh)}h`;
};

export const formatDuration = (ms: number) => {
    const seconds = Math.floor(ms / 1000);
    const remainingSeconds = seconds % 60;

    const minutes = Math.floor(seconds / 60);
    const remainingMinutes = minutes % 60;

    const hours = Math.floor(minutes / 60);

    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
    const formattedMinutes = remainingMinutes.toString().padStart(2, '0');
    const formattedHours = hours.toString().padStart(2, '0');
    const hoursString = hours > 0 ? `${formattedHours}:` : '';

    return `${hoursString}${formattedMinutes}:${formattedSeconds}`;
};
