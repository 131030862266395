import { Heading, Level, Text, TextType } from '@defa/defa-component-library';

import i18n from '../../i18n';
import { ConnectorStatus } from '../../models/connector';
import { Info } from './status-card.styles';

interface UnavailableStatusCardProps {
    status: ConnectorStatus.OCCUPIED | ConnectorStatus.UNAVAILABLE;
    textColor: string;
}

export function UnavailableStatusCardContent({ status, textColor }: UnavailableStatusCardProps) {
    return (
        <Info>
            <Heading level={Level.h4Medium} color={textColor}>
                {i18n.t(`statusCard.subtitle.${status}`)}
            </Heading>
            <Text type={TextType.body} color={textColor}>
                {i18n.t(`statusCard.description.${status}`)}
            </Text>
        </Info>
    );
}
