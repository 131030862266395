import { ChargerData, ConnectorStatus, ConnectorType } from '../../models/connector';

export function unavailableCharger(chargerId: string | undefined): ChargerData {
    return {
        name: chargerId ?? '',
        chargeSystemName: '',
        organizationName: '',
        connectorType: ConnectorType.TYPE2_CABLE,
        maxPower: 0,
        currency: '',
        reservationAmount: 0,
        tariff: null,
        status: ConnectorStatus.UNAVAILABLE,
        offline: true,
    };
}
