import { Card, Heading, Icon, IconName, Level, Size, useTheme } from '@defa/defa-component-library';
import { ReactNode } from 'react';

import { CardContent } from '.';
import { Divider, IconContainer } from './card.styles';

interface CardBaseProps {
    background?: string;
    children?: ReactNode;
    heading: string;
    hideDivider?: boolean;
    icon: IconName;
    iconBackgroundColor?: string;
    iconColor: string;
    iconSize: number;
    secondaryText: string;
    textColor?: string;
    titleColor?: string;
}

export function CardBase({
    background,
    children,
    heading,
    hideDivider,
    icon,
    iconBackgroundColor,
    iconColor,
    iconSize,
    secondaryText,
    textColor,
    titleColor,
}: CardBaseProps) {
    const theme = useTheme();

    return (
        <Card
            clickable={false}
            size={Size.LARGE}
            title={
                <Heading level={Level.h2} color={titleColor ?? theme.foreground.default}>
                    {heading}
                </Heading>
            }
            secondaryText={
                <Heading level={Level.h4Medium} color={textColor ?? theme.foreground.alternative}>
                    {secondaryText}
                </Heading>
            }
            thumbnail={
                <IconContainer color={iconBackgroundColor}>
                    <Icon icon={icon} color={iconColor} size={iconSize} />
                </IconContainer>
            }
            background={background ?? theme.background.subtle}
        >
            <CardContent>
                {!hideDivider && <Divider />}
                {children}
            </CardContent>
        </Card>
    );
}
