import { IconName, Theme } from '@defa/defa-component-library';

import i18n from '../i18n';
import { ConnectorStatus, ConnectorType } from '../models/connector';

export type SessionConnectorStatus =
    | ConnectorStatus.AVAILABLE
    | ConnectorStatus.CHARGING
    | ConnectorStatus.FINISHING
    | ConnectorStatus.SUSPENDED_EV
    | ConnectorStatus.UNAVAILABLE;
export type AnonymousConnectorStatus =
    | ConnectorStatus.AVAILABLE
    | ConnectorStatus.OCCUPIED
    | ConnectorStatus.UNAVAILABLE;

export function sessionConnectorStatus(
    status: ConnectorStatus,
    offline: boolean
): SessionConnectorStatus {
    if (offline) {
        return ConnectorStatus.UNAVAILABLE;
    }

    switch (status) {
        case ConnectorStatus.PREPARING:
        case ConnectorStatus.EV_CONNECTED:
            return ConnectorStatus.AVAILABLE;
        case ConnectorStatus.CHARGING:
        case ConnectorStatus.RESERVED:
        case ConnectorStatus.OCCUPIED:
            return ConnectorStatus.CHARGING;
        case ConnectorStatus.SUSPENDED_EVSE:
        case ConnectorStatus.FACILITY_FINISHING:
            return ConnectorStatus.FINISHING;
        case ConnectorStatus.RESTARTING:
        case ConnectorStatus.FAULTED:
            return ConnectorStatus.UNAVAILABLE;
        default:
            return status;
    }
}
export function anonymousConnectorStatus(
    status: ConnectorStatus,
    offline: boolean
): AnonymousConnectorStatus {
    const sessionStatus = sessionConnectorStatus(status, offline);
    switch (sessionStatus) {
        case ConnectorStatus.CHARGING:
        case ConnectorStatus.FINISHING:
        case ConnectorStatus.SUSPENDED_EV:
            return ConnectorStatus.OCCUPIED;
        default:
            return sessionStatus;
    }
}

function getConnectorTypeIcon(type?: ConnectorType): IconName {
    switch (type) {
        case ConnectorType.TYPE1:
            return 'typeType1';
        case ConnectorType.TYPE2_CABLE:
            return 'typeType2Cable';
        case ConnectorType.TYPE2_SOCKET:
            return 'typeType2Socket';
        case ConnectorType.CHADEMO:
            return 'typeChademo';
        case ConnectorType.CCS:
            return 'typeCCS';
        case ConnectorType.SHUKO:
        case ConnectorType.TESLA_SC:
        case ConnectorType.UNKNOWN:
        default:
            return 'boltCircle';
    }
}

export function getStatusHeading(status: ConnectorStatus, name: string): string {
    switch (status) {
        case ConnectorStatus.FINISHING:
        case ConnectorStatus.SUSPENDED_EV:
            return i18n.t(`statusCard.title.${status}`);
        default:
            return name;
    }
}

export function getStatusText(status: ConnectorStatus): string {
    switch (status) {
        case ConnectorStatus.AVAILABLE:
        case ConnectorStatus.CHARGING:
        case ConnectorStatus.FINISHING:
        case ConnectorStatus.OCCUPIED:
        case ConnectorStatus.SUSPENDED_EV:
        case ConnectorStatus.UNAVAILABLE:
            return i18n.t(`status.${status}`);
        default:
            return '';
    }
}

interface ConnectorVisualStatus {
    background: string;
    icon: IconName;
    iconBackgroundColor: string;
    iconColor: string;
    iconSize: number;
    stop: boolean;
    textColor: string;
    titleColor: string;
}

export function getVisualStatus(
    status: AnonymousConnectorStatus | SessionConnectorStatus,
    theme: Theme,
    offline = false,
    connectorType?: ConnectorType
): ConnectorVisualStatus {
    const defaultVisualStatus = {
        background: theme.background.subtle,
        icon: getConnectorTypeIcon(connectorType),
        iconBackgroundColor: theme.foreground.success,
        iconColor: theme.foreground.light,
        iconSize: 16,
        stop: false,
        textColor: theme.foreground.alternative,
        titleColor: theme.foreground.default,
    };

    if (offline || status === ConnectorStatus.UNAVAILABLE) {
        return {
            ...defaultVisualStatus,
            iconBackgroundColor: theme.foreground.disabled,
        };
    }

    switch (status) {
        case ConnectorStatus.CHARGING:
            return {
                ...defaultVisualStatus,
                background: theme.background.charging,
                iconBackgroundColor: theme.foreground.light,
                iconColor: theme.background.charging,
                stop: true,
                textColor: theme.foreground.light,
                titleColor: theme.foreground.light,
            };
        case ConnectorStatus.OCCUPIED:
            return {
                ...defaultVisualStatus,
                iconBackgroundColor: theme.background.charging,
            };
        case ConnectorStatus.FINISHING:
        case ConnectorStatus.SUSPENDED_EV:
            return {
                ...defaultVisualStatus,
                icon: 'alertTriangle',
                iconBackgroundColor: theme.background.subtle,
                iconColor: theme.foreground.attention,
                iconSize: 40,
            };
        case ConnectorStatus.AVAILABLE:
        default:
            // Do nothing
            return defaultVisualStatus;
    }
}
