import { Heading, Level, Loading, Text, TextType, useTheme } from '@defa/defa-component-library';
import { lazy, Suspense, useContext, useMemo } from 'react';

import { CookieConsentContext } from '../../contexts/cookie-consent';
import i18n from '../../i18n';
import { Section } from '../app-layout';

interface PaymentSectionProps {
    clientSecret?: string;
    offline?: boolean;
    paymentCard?: string;
    reservationCost?: string;
    title: string;
}

export function PaymentSection({
    clientSecret,
    offline,
    paymentCard,
    reservationCost,
    title,
}: PaymentSectionProps) {
    const theme = useTheme();
    const { acceptedEssential } = useContext(CookieConsentContext);

    const PaymentCard = useMemo(
        () =>
            acceptedEssential
                ? lazy(() =>
                      import('../../fractions/payment-card').then((module) => ({
                          default: module.PaymentCard,
                      }))
                  )
                : null,
        [acceptedEssential]
    );

    return (
        <Section>
            <Heading level={Level.h3}>{title}</Heading>
            <Suspense fallback={<Loading />}>
                {PaymentCard ? (
                    <PaymentCard
                        clientSecret={clientSecret}
                        offline={offline}
                        paymentCard={paymentCard}
                        reservationCost={reservationCost}
                    />
                ) : (
                    <Text type={TextType.body} color={theme.foreground.default}>
                        {i18n.t('cookies.missingConsent')}
                    </Text>
                )}
            </Suspense>
        </Section>
    );
}
