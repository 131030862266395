import { useTheme } from '@defa/defa-component-library';
import * as Sentry from '@sentry/react';
import { useRouteError } from 'react-router-dom';

import { Content } from './fractions/app-layout';
import { CardBase } from './fractions/card';
import i18n from './i18n';

export default function ErrorPage() {
    const theme = useTheme();
    const error = useRouteError();
    Sentry.captureException(error);
    console.error(error);

    return (
        <Content>
            <CardBase
                heading={i18n.t('error.generic.title')}
                hideDivider={true}
                icon="alertTriangle"
                iconColor={theme.foreground.attention}
                iconSize={40}
                secondaryText={i18n.t('error.generic.description')}
            />
        </Content>
    );
}
