import { useCallback, useEffect, useRef, useState } from 'react';

export function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        const id = setInterval(() => {
            savedCallback.current();
        }, delay);

        return () => {
            clearInterval(id);
        };
    }, [delay]);
}

function useStorage<T>(
    storage: Storage,
    key: string,
    defaultValue?: T
): [T | undefined, (value: T) => void, () => void] {
    const [value, setValue] = useState<T | undefined>(() => {
        try {
            const saved = storage.getItem(key);
            if (saved !== null) {
                return JSON.parse(saved) as T;
            }
            return defaultValue;
        } catch {
            return defaultValue;
        }
    });

    useEffect(() => {
        if (value !== undefined) {
            const stringValue = JSON.stringify(value);
            storage.setItem(key, stringValue);
        }
    }, [key, value, storage]);

    const removeValue = useCallback(() => {
        storage.removeItem(key);
        setValue(undefined);
    }, [key, storage]);

    return [value, setValue, removeValue];
}

export function useLocalStorage<T>(key: string, defaultValue?: T) {
    return useStorage<T>(localStorage, key, defaultValue);
}
export function useSessionStorage<T>(key: string, defaultValue?: T) {
    return useStorage<T>(sessionStorage, key, defaultValue);
}
