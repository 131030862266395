import { BaseChargerData, ChargerData } from './connector';
import { ErrorData } from './error';
import { UserData } from './user';

export interface SessionData extends BaseChargerData {
    cost: number;
    energy: number;
    startTime: number;
}

export interface SessionLoaderData {
    charger: ChargerData | ErrorData;
    session: SessionData | ErrorData;
    user: UserData | ErrorData;
}

export interface SummaryLoaderData {
    summary: SummaryData | ErrorData;
    user: UserData | ErrorData;
}

export interface SummaryData {
    chargerId: string;
    cost: number;
    currency: string;
    duration: number;
    energy: number;
    paymentStatus: PaymentStatus;
}

export enum PaymentStatus {
    COMPLETED = 'COMPLETED',
    FAILED_DONT_RETRY = 'FAILED_DONT_RETRY',
    CANCELLED = 'CANCELLED',
    PENDING = 'PENDING',
    FAILED_RETRY = 'FAILED_RETRY',
    CREDITED = 'CREDITED',
    AUTHENTICATING = 'AUTHENTICATING',
    UNKNOWN = 'UNKNOWN',
}
