import {
    Button,
    Composite,
    Heading,
    Icon,
    Level,
    Text,
    TextType,
    useTheme,
    Variant,
} from '@defa/defa-component-library';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import i18n from '../../i18n';
import { ConnectorStatus } from '../../models/connector';
import { SessionData } from '../../models/session';
import api from '../../utils/api';
import { formatCost, formatDuration, formatKwh } from '../../utils/formatters';
import { useInterval } from '../../utils/hooks';
import { getPriceInfo } from '../../utils/priceInfo';
import {
    getStatusHeading,
    getStatusText,
    getVisualStatus,
    SessionConnectorStatus,
} from '../../utils/status';
import { Divider } from '../app-layout';
import { CardBase } from '../card';
import { ChargerInfoItem } from '../charger-info-item';
import { ReceiptContent } from '../receipt-card';
import { ClickableArea } from './status-card.styles';

interface SessionStatusCardProps {
    session: SessionData;
    status: SessionConnectorStatus;
}

export function SessionStatusCard({ session, status }: SessionStatusCardProps) {
    const theme = useTheme();
    const { chargerId, userId } = useParams();

    const [duration, setDuration] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showLongDescription, setShowLongDescription] = useState(false);

    const {
        connectorType,
        cost,
        currency,
        energy,
        name,
        offline,
        organizationName,
        startTime,
        tariff,
    } = session;

    const { background, icon, iconBackgroundColor, iconColor, iconSize, textColor, titleColor } =
        getVisualStatus(status, theme, offline, connectorType);
    const heading = getStatusHeading(status, name);
    const statusText = getStatusText(status);
    const priceText = getPriceInfo(tariff, currency);

    const onClickStop = useCallback(() => {
        if (chargerId && userId) {
            setIsLoading(true);
            api.stopCharging(chargerId, userId)
                .then((response) => {
                    if (response.ok) {
                        setErrorMessage(null);
                    } else {
                        setErrorMessage(i18n.t('error.generic.title'));
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setErrorMessage(i18n.t('error.generic.title'));
                    setIsLoading(false);

                    Sentry.captureException(error);
                    console.error(error);
                });
        }
    }, [chargerId, userId]);

    useInterval(() => {
        setDuration(new Date().getTime() - startTime);
    }, 1000);

    useEffect(() => {
        if (status !== ConnectorStatus.CHARGING) {
            setIsLoading(false);
        }
    }, [status]);

    return (
        <CardBase
            background={background}
            heading={heading}
            icon={icon}
            iconBackgroundColor={iconBackgroundColor}
            iconColor={iconColor}
            iconSize={iconSize}
            secondaryText={statusText}
            textColor={textColor}
            titleColor={titleColor}
        >
            <ClickableArea onClick={() => setShowLongDescription((prev) => !prev)}>
                <Composite align="flex-start">
                    <ChargerInfoItem
                        icon="costFill"
                        subText={
                            showLongDescription
                                ? priceText ?? i18n.t('priceInformation.free.short')
                                : null
                        }
                        text={i18n.t('statusCard.button.estimatedPrice', {
                            value: formatCost(cost, currency),
                        })}
                        textColor={textColor}
                    />
                    <Icon icon="information" size={16} key="information" color={textColor} />
                </Composite>
            </ClickableArea>
            <ChargerInfoItem icon="boltFill" text={formatKwh(energy)} textColor={textColor} />
            <ChargerInfoItem
                icon="clockFill"
                text={formatDuration(duration)}
                textColor={textColor}
            />
            <Composite justify="flex-start">
                <Heading level={Level.h4Medium} color={textColor}>
                    {i18n.t('statusCard.operator', { operator: organizationName })}
                </Heading>
            </Composite>
            {(status === ConnectorStatus.CHARGING || status === ConnectorStatus.SUSPENDED_EV) && (
                <Button
                    disabled={isLoading}
                    loading={isLoading}
                    variant={
                        status === ConnectorStatus.SUSPENDED_EV
                            ? Variant.PRIMARY
                            : Variant.PRIMARY_ALTERNATIVE
                    }
                    onClick={onClickStop}
                >
                    {isLoading
                        ? i18n.t('statusCard.button.stopLoading')
                        : i18n.t('statusCard.button.stop')}
                </Button>
            )}
            {errorMessage && (
                <Text type={TextType.description} color={textColor}>
                    {errorMessage}
                </Text>
            )}
            {status === ConnectorStatus.CHARGING && (
                <Composite justify="flex-start">
                    <Heading level={Level.h4Medium} color={textColor}>
                        {i18n.t('statusCard.receipt.description')}
                    </Heading>
                    <Icon icon="arrowDown" size={24} key="arrowDown" color={textColor} />
                </Composite>
            )}
            {status === ConnectorStatus.FINISHING && (
                <>
                    <Divider />
                    <ReceiptContent />
                </>
            )}
        </CardBase>
    );
}
