import {
    Button,
    Composite,
    Heading,
    Icon,
    IconName,
    Level,
    Text,
    TextType,
    useTheme,
} from '@defa/defa-component-library';

import i18n from '../../i18n';
import { formatKw } from '../../utils/formatters';
import { Divider } from '../app-layout';
import { ChargerInfoItem } from '../charger-info-item';
import { PlugInVehicleSection } from './status-card.styles';

interface AvailableStatusCardProps {
    connectorIcon: IconName;
    connectorType: string;
    promptPlugIn?: boolean;
    maxPower: number;
    onClickStart?: () => void;
    organizationName: string;
    priceText: string | null;
    showStartButton: boolean;
    startButtonError?: boolean;
    startButtonLoading?: boolean;
    textColor: string;
}

export function AvailableStatusCardContent({
    connectorIcon,
    connectorType,
    promptPlugIn,
    maxPower,
    onClickStart,
    organizationName,
    priceText,
    showStartButton,
    startButtonError,
    startButtonLoading,
    textColor,
}: AvailableStatusCardProps) {
    const theme = useTheme();

    return (
        <>
            {promptPlugIn && (
                <>
                    <PlugInVehicleSection>
                        <Heading level={Level.h4}>
                            {i18n.t('statusCard.subtitle.AVAILABLE')}
                        </Heading>
                        <Icon icon="outlet" size={75} key="information" color={textColor} />
                    </PlugInVehicleSection>
                    <Divider />
                </>
            )}
            <ChargerInfoItem
                icon="costFill"
                text={priceText ?? i18n.t('priceInformation.free.short')}
                textColor={textColor}
            />
            <ChargerInfoItem icon="boltFill" text={formatKw(maxPower)} textColor={textColor} />
            <ChargerInfoItem
                icon={connectorIcon}
                text={i18n.t(`connectorType.${connectorType}`)}
                textColor={textColor}
            />
            <Composite justify="flex-start">
                <Heading level={Level.h4Medium} color={textColor}>
                    {i18n.t('statusCard.operator', { operator: organizationName })}
                </Heading>
            </Composite>
            {startButtonError && (
                <Text type={TextType.description} color={theme.foreground.error}>
                    {i18n.t('error.start')}
                </Text>
            )}
            {showStartButton && (
                <Button
                    disabled={startButtonLoading}
                    loading={startButtonLoading}
                    onClick={onClickStart}
                >
                    {i18n.t('statusCard.button.start')}
                </Button>
            )}
        </>
    );
}
