import { Button, Link, Modal, Text, TextType, Variant } from '@defa/defa-component-library';
import { useContext } from 'react';

import { CookieConsentContext } from '../../contexts/cookie-consent';
import i18n from '../../i18n';
import { Section } from '../app-layout';

export function CookieModal({ showModal = false }: { showModal?: boolean }) {
    const { acceptAll, acceptEssentialOnly } = useContext(CookieConsentContext);

    return (
        <Modal
            width="min(80%, 400px)"
            showModal={showModal}
            bodyContent={
                <Section>
                    <Text type={TextType.body}>
                        {i18n.t('cookies.description')}
                        <Link href={i18n.t('url.privacyPolicy.cloudcharge')} newTab inline>
                            {i18n.t('footer.privacyPolicy')}
                        </Link>
                        .
                    </Text>
                    <Text type={TextType.body}>{i18n.t('cookies.appPromo')}</Text>
                </Section>
            }
            actionContent={
                <Section>
                    <Button
                        text={i18n.t('cookies.acceptAll')}
                        name="accept-all-cookies"
                        onClick={acceptAll}
                        variant={Variant.PRIMARY}
                    />
                    <Button
                        text={i18n.t('cookies.acceptEssential')}
                        name="accept-essential-cookies"
                        onClick={acceptEssentialOnly}
                        variant={Variant.SECONDARY}
                    />
                </Section>
            }
        />
    );
}
