import { Tariff } from './tariff';

export interface BaseChargerData {
    chargeSystemName: string;
    connectorType: ConnectorType;
    currency: string;
    name: string;
    offline: boolean;
    organizationName: string;
    reservationAmount: number;
    status: ConnectorStatus;
    tariff: Tariff | null;
}

export interface ChargerData extends BaseChargerData {
    maxPower: number;
}

export enum ConnectorType {
    'TYPE1' = 'TYPE1',
    'TYPE2_CABLE' = 'TYPE2_CABLE',
    'TYPE2_SOCKET' = 'TYPE2_SOCKET',
    'TYPE3' = 'TYPE3',
    'CHADEMO' = 'CHADEMO',
    'CCS' = 'CCS',
    'SHUKO' = 'SHUKO',
    'TESLA_SC' = 'TESLA_SC',
    'UNKNOWN' = 'UNKNOWN',
}

export enum ConnectorStatus {
    'AVAILABLE' = 'AVAILABLE',
    'OCCUPIED' = 'OCCUPIED',
    'FAULTED' = 'FAULTED',
    'UNAVAILABLE' = 'UNAVAILABLE',
    'RESERVED' = 'RESERVED',
    'FINISHING' = 'FINISHING',
    'PREPARING' = 'PREPARING',
    'CHARGING' = 'CHARGING',
    'SUSPENDED_EV' = 'SUSPENDED_EV',
    'SUSPENDED_EVSE' = 'SUSPENDED_EVSE',
    'EV_CONNECTED' = 'EV_CONNECTED',
    'RESTARTING' = 'RESTARTING',
    'FACILITY_FINISHING' = 'FACILITY_FINISHING',
}
