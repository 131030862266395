import * as Sentry from '@sentry/react';

import api from './api';

export function callStartPingWithErrorCapture(chargerId: string | undefined) {
    if (chargerId) {
        api.startPing(chargerId)
            .then((response) => {
                if (!response.ok) {
                    Sentry.captureMessage('Failed to start ping', {
                        level: 'error',
                        extra: { data: response.data },
                    });
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
            });
    }
}
