import { useTheme } from '@defa/defa-component-library';

import { ChargerData, ConnectorStatus } from '../../models/connector';
import { getPriceInfo } from '../../utils/priceInfo';
import {
    AnonymousConnectorStatus,
    getStatusHeading,
    getStatusText,
    getVisualStatus,
} from '../../utils/status';
import { CardBase } from '../card';
import { AvailableStatusCardContent } from './available-status-card-content';
import { UnavailableStatusCardContent } from './unavailable-status-card-content';

interface ChargerStatusCardProps {
    charger: ChargerData;
    onClickStart?: () => void;
    promptPlugIn?: boolean;
    showStart: boolean;
    startButtonError?: boolean;
    startButtonLoading?: boolean;
    status: AnonymousConnectorStatus;
}

export function ChargerStatusCard({
    charger,
    onClickStart,
    promptPlugIn,
    showStart,
    startButtonError,
    startButtonLoading,
    status,
}: ChargerStatusCardProps) {
    const theme = useTheme();

    const { connectorType, currency, maxPower, name, offline, organizationName, tariff } = charger;
    const { icon, iconBackgroundColor, iconColor, iconSize, textColor } = getVisualStatus(
        status,
        theme,
        offline,
        connectorType
    );
    const heading = getStatusHeading(status, name);
    const statusText = getStatusText(status);
    const priceText = getPriceInfo(tariff, currency);

    const unavailable =
        status === ConnectorStatus.UNAVAILABLE || status === ConnectorStatus.OCCUPIED;

    return (
        <CardBase
            heading={heading}
            icon={icon}
            iconBackgroundColor={iconBackgroundColor}
            iconColor={iconColor}
            iconSize={iconSize}
            secondaryText={statusText}
        >
            {unavailable ? (
                <UnavailableStatusCardContent status={status} textColor={textColor} />
            ) : (
                <AvailableStatusCardContent
                    connectorIcon={icon}
                    connectorType={connectorType}
                    promptPlugIn={promptPlugIn}
                    maxPower={maxPower}
                    onClickStart={onClickStart}
                    organizationName={organizationName}
                    priceText={priceText}
                    showStartButton={showStart}
                    startButtonError={startButtonError}
                    startButtonLoading={startButtonLoading}
                    textColor={textColor}
                />
            )}
        </CardBase>
    );
}
