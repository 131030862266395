import { logos } from '../../assets/logos';
import { Image, Root } from './header.styles';

const brand = import.meta.env.VITE_BRAND_KEY;

export function Header() {
    return (
        <Root>
            <Image src={logos[brand]} alt="Logo" />
        </Root>
    );
}
