import { Composite, Icon, IconName, Text, TextType } from '@defa/defa-component-library';

import { Texts } from './charger-info-item.styles';

interface ChargerInfoItemProps {
    icon: IconName;
    subText?: string | null;
    text: string;
    textColor: string;
}

export function ChargerInfoItem({ icon, subText, text, textColor }: ChargerInfoItemProps) {
    return (
        <Composite align="flex-start" justify="flex-start">
            <Icon icon={icon} size={16} color={textColor} />
            <Texts>
                <Text type={TextType.body} color={textColor}>
                    {text}
                </Text>
                {subText && (
                    <Text type={TextType.body} color={textColor}>
                        {subText}
                    </Text>
                )}
            </Texts>
        </Composite>
    );
}
