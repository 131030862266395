import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { useLoaderData, useParams } from 'react-router-dom';

import { Content } from '../../fractions/app-layout';
import { AppPromo } from '../../fractions/app-promo';
import { SummaryCard } from '../../fractions/summary-card';
import { PaymentStatus, SummaryLoaderData } from '../../models/session';
import api from '../../utils/api';

export function Summary() {
    const { userId } = useParams();
    const { summary: initialSummary, user } = useLoaderData() as SummaryLoaderData;

    const [summary, setSummary] = useState(initialSummary);
    const summaryError = !summary || 'message' in summary;
    const userError = !user || 'message' in user;

    const [paymentProcessing, setPaymentProcessing] = useState(
        !summaryError && summary.paymentStatus === PaymentStatus.UNKNOWN
    );

    useEffect(() => {
        let timoutId: NodeJS.Timeout;

        const fetchSummary = async () => {
            try {
                const response = await api.getChargeSessionSummaryData(userId);
                if (response.data) {
                    setSummary(response.data);
                    if (response.ok && response.data.paymentStatus !== PaymentStatus.UNKNOWN) {
                        setPaymentProcessing(false);
                    } else {
                        // If there was an error or the paymentStatus is still UNKNOWN, fetch again in 3 seconds
                        timoutId = setTimeout(() => void fetchSummary(), 3000);
                    }
                }
            } catch (error) {
                Sentry.captureException(error);
                console.error(error);
            }
        };

        if (paymentProcessing) {
            void fetchSummary();
        }

        return () => clearTimeout(timoutId);
    }, [paymentProcessing, userId]);

    return (
        <Content>
            {!summaryError && !userError && (
                <SummaryCard
                    cost={summary.cost}
                    currency={summary.currency}
                    duration={summary.duration}
                    energy={summary.energy}
                    initialEmail={user.email}
                    paymentProcessing={paymentProcessing}
                />
            )}
            <AppPromo />
        </Content>
    );
}
